<template>
<div class="action-selection__content">
    <h1>{{ $t('EventSelection.title') }}</h1>
    <p>
        {{ $t('EventSelection.message') }}
    </p>
    <PtyInput id="pty__yj51x2zfc" class="slide-down" v-model="searchValue" placeholder="Cerca" block icon="bx-search" iconType="box-icon" :class="{collapsed: !searchVisible}" :loading="searchLoading" />

    <PtySelector :options="possibleEvents" v-model="selectedEvent" :value="selectedEvent" />

</div>
</template>


<script>
import {eventService} from '@/rest';

import PtyInput from '../../components/PtyInput.vue';
import PtySelector from '../../components/PtySelector.vue';
import LocationIcon from '../../assets/images/icons/location.svg';

export default {
    name: 'EventSelection',
    props: {
        searchVisible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PtySelector,
        PtyInput
    },
    data: () => ({
        possibleEvents: [],
        selectedEvent: 1,
        searchValue: '',

        searchLoading: false,
        searchTimeout: null
    }),

    async mounted() {
        var vm = this;
        this.$emit('input', this.selectedEvent);

        this.params = {};

        const locationResult = await this.$getLocation();
        if(locationResult != null){
            vm.params.actual_latitude = locationResult.coords.latitude;
            vm.params.actual_longitude = locationResult.coords.longitude;
        }

        const eventsResult = await eventService.getPublic(vm.params);

        const events = eventsResult.data || [];

        this.possibleEvents = events.map(event => {
            return {
                id: event.id,
                title: event.name,
                caption: event.business.address + ', ' + event.business.city,
                icon: LocationIcon,
                day: (new Date(event.start_at)).getDate(),
                month: (new Date(event.start_at)).toLocaleString('default', { month: 'short' }).toUpperCase(),
                extra_caption: event.description,
                bottom_label: event.type == 'FREE' ? vm.$t('EventSelection.free') : vm.$t('EventSelection.tables')
            }
        });

        // if gps granted, set first label to "Il più vicino a te"
        if (this.possibleEvents.length > 0 && vm.params.actual_latitude && vm.params.actual_longitude) {
            this.possibleEvents[0].label = this.$t('EventSelection.closest');

            // select 1st
            this.selectedEvent = this.possibleEvents[0].id;
        }

        
    },

    methods: {
        doSearch() {
            this.searchLoading = false;
            eventService.getPublic({
                ...this.params,
                search: this.searchValue
            }).then((eventsResult) => {
                this.possibleEvents = eventsResult.data != null ? eventsResult.data.map(event => {
                    return {
                        id: event.id,
                        title: event.name,
                        caption: event.business.address + ', ' + event.business.city,
                        icon: LocationIcon,
                        day: (new Date(event.start_at)).getDate(),
                        month: (new Date(event.start_at)).toLocaleString('default', { month: 'short' }).toUpperCase(),
                        extra_caption: event.description
                    }
                }) : [];
            });
        }
    },

    watch: {
        selectedEvent() {
            this.$emit('input', this.selectedEvent);
        },

        searchValue() {
            this.searchLoading = true;

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = setTimeout(() => {
                this.doSearch();
            }, 500);
        }
    }
}
</script>
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
  color: #000;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.0425rem;
  margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>