<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('TagSelection.title') }}</h1>
        <p>
            {{ $t('TagSelection.message') }}
        </p>
        <PtySelector :options="possibleTags" v-model="selectedTag" :value="selectedTag" colorizeMode="stroke" iconType="png" size="small" :block="false"/>

    </div>
</div>
</template>

<script>
// import assets\images\tags pngs: ardente, devil, discreto, innamorato, innocente, malizioso, queen, romantico, superdotato, timido
import ArdenteIcon from '../../assets/images/tags/ardente.png';
import DevilIcon from '../../assets/images/tags/devil.png';
import DiscretoIcon from '../../assets/images/tags/discreto.png';
import InnamoratoIcon from '../../assets/images/tags/innamorato.png';
import InnocenteIcon from '../../assets/images/tags/innocente.png';
import MaliziosoIcon from '../../assets/images/tags/malizioso.png';
import QueenIcon from '../../assets/images/tags/queen.png';
import RomanticoIcon from '../../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../../assets/images/tags/superdotato.png';
import TimidoIcon from '../../assets/images/tags/timido.png';

import PtySelector from '../../components/PtySelector.vue';


export default {
    name: 'TagSelection',
    components: {
        PtySelector
    },
    data: () => {
        return {
            selectedTag: null
        }
    },

    props: {
        value: {
            type: String,
            default: null
        }
    },

    mounted() {
        this.selectedTag = this.value;
    },

    computed: {
        possibleTags(){
            return [
                { id: 'ARDENTE', title: this.$t('TagSelection.Tags.ARDENTE.title'), caption: this.$t('TagSelection.Tags.ARDENTE.caption'), icon: ArdenteIcon },
                { id: 'DEVIL', title: this.$t('TagSelection.Tags.DEVIL.title'), caption: this.$t('TagSelection.Tags.DEVIL.caption'), icon: DevilIcon },
                { id: 'DISCRETO', title: this.$t('TagSelection.Tags.DISCRETO.title'), caption: this.$t('TagSelection.Tags.DISCRETO.caption'), icon: DiscretoIcon },
                { id: 'INNAMORATO', title: this.$t('TagSelection.Tags.INNAMORATO.title'), caption: this.$t('TagSelection.Tags.INNAMORATO.caption'), icon: InnamoratoIcon },
                { id: 'INNOCENTE', title: this.$t('TagSelection.Tags.INNOCENTE.title'), caption: this.$t('TagSelection.Tags.INNOCENTE.caption'), icon: InnocenteIcon },
                { id: 'MALIZIOSO', title: this.$t('TagSelection.Tags.MALIZIOSO.title'), caption: this.$t('TagSelection.Tags.MALIZIOSO.caption'), icon: MaliziosoIcon },
                { id: 'QUEEN', title: this.$t('TagSelection.Tags.QUEEN.title'), caption: this.$t('TagSelection.Tags.QUEEN.caption'), icon: QueenIcon },
                { id: 'ROMANTICO', title: this.$t('TagSelection.Tags.ROMANTICO.title'), caption: this.$t('TagSelection.Tags.ROMANTICO.caption'), icon: RomanticoIcon },
                { id: 'SUPERDOTATO', title: this.$t('TagSelection.Tags.SUPERDOTATO.title'), caption: this.$t('TagSelection.Tags.SUPERDOTATO.caption'), icon: SuperdotatoIcon },
                { id: 'TIMIDO', title: this.$t('TagSelection.Tags.TIMIDO.title'), caption: this.$t('TagSelection.Tags.TIMIDO.caption'), icon: TimidoIcon },
            ]
        }
    },

    watch: {
        selectedTag() {
            this.$emit('input', this.selectedTag);
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>