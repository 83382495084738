<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('InterestSelection.title') }}</h1>
        <p style="white-space: pre-line;">
            {{ $t('InterestSelection.message') }}
        </p>
        <PtySelector :options="possibleInterests" v-model="selectedInterests" colorizeMode="stroke" multiple/>

    </div>
</div>
</template>

<script>
import PtySelector from '../../components/PtySelector.vue';

import LoveIcon from '../../assets/images/icons/love.svg';
import FriendsIcon from '../../assets/images/icons/friends.svg';
import CoffeeIcon from '../../assets/images/icons/coffee.svg';

export default {
    name: 'InterestSelection',
    components: {
        PtySelector
    },
    data: () => ({
        selectedInterests: []
    }),

    computed: {
        possibleInterests() {
            return [
                { id: 'AMORE', title: this.$t('InterestSelection.options.amore.title'), caption: this.$t('InterestSelection.options.amore.caption'), icon: LoveIcon },
                { id: 'AMICI', title: this.$t('InterestSelection.options.amici.title'), caption: this.$t('InterestSelection.options.amici.caption'), icon: FriendsIcon },
                { id: 'ESPERIENZA_LIBERTINA', title: this.$t('InterestSelection.options.esperienza_libertina.title'), caption: this.$t('InterestSelection.options.esperienza_libertina.caption'), icon: CoffeeIcon }
            ];
        }
    },

    props: {
        value: {
            type: Array,
            default: () => []
        }
    },

    mounted() {
        if(this.value && this.value.length > 0) {
            this.selectedInterests.push(...this.value);
        }
    },

    watch: {
        selectedInterests() {
            this.$emit('input', this.selectedInterests);
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>