<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('TableMode.title') }}</h1>
        <p>
            {{ $t('TableMode.message') }}
        </p>

        <PtySelector :options="possibleTableModes" v-model="selectedTableMode" :value="selectedTableMode" />

    </div>
</div>
</template>

<script>
import PtySelector from '../../components/PtySelector.vue';

import UserTickIcon from '../../assets/images/icons/user-tick.svg';
import ShuffleIcon from '../../assets/images/icons/shuffle.svg';

export default {
    name: 'TableModeSelection',
    components: {
        PtySelector
    },
    data: () => ({
        selectedTableMode: 'AUTO'
    }),

    computed: {
        possibleTableModes() {
            return [
                { id: 'AUTO', title: this.$t('TableMode.options.auto.title'), caption: this.$t('TableMode.options.auto.caption'), icon: ShuffleIcon },
                { id: 'MANUAL', title: this.$t('TableMode.options.manual.title'), caption: this.$t('TableMode.options.manual.caption'), icon: UserTickIcon }
            ];
        }
    },

    mounted() {
        this.$emit('input', this.selectedTableMode);
    },

    watch: {
        selectedTableMode() {
            this.$emit('input', this.selectedTableMode);
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>