<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('TableNumber.title') }}</h1>
        <p>
            {{ $t('TableNumber.message') }}
        </p>
    </div>

    <PtyButton id="pty__k9tvg3ov9" shadow no-disable style="margin-bottom: -80px!important;">
        <h2 style="color: white;" v-if="table != null">{{ table.name }}</h2>
    </PtyButton>
    <TableIllustration />
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import PtyButton from '../../components/PtyButton.vue';
import TableIllustration from '../../assets/images/illustrations/table.svg';

import {
    tableService
} from '@/rest';
export default {
    name: 'TableNumber',
    components: {
        TableIllustration,
        PtyButton,
    },
    data: () => ({
        table: null,
    }),

    async mounted() {
        const loading = this.$vs.loading();
        try {
            const tableResult = await tableService.assign();
            if (tableResult.code == 'resourceLimitReached') {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('CheckIn.eventIsFull'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
                loading.close();
                return;
            }

            this.table = tableResult.data;
            this.$emit('input', this.table);

        } catch (e) {
            Sentry.captureException(e);
            this.$vs.notification({
                title: 'Errore',
                text: this.$t('TableNumber.error'),
                position: 'top-center',
                color: 'primary', duration: 5000,
            });
        }
        loading.close();
    },

    watch: {

    }
}
</script>

<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 20px;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

h2 {
    color: #FFF;
    font-family: Avenir;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}
</style>
